$(document).ready(function(){
  
    $('#banner_holder').slick({
      autoplay:true,
      autoplaySpeed:3000,
      arrows:false,
      dots: true,
      infinite:true,
      adaptiveHeight: true,
      draggable:false,
      fade: false,
      slidesToShow: 1
    });
  
    $('.vi_con').slick({
      infinite: false,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });
    
    var currPosition;
    var draggerWT = $('.dragger').innerWidth();
    var draggerBoundWT = ($('.selector_hld').innerWidth()-draggerWT)/2;
    var draggable = window.draggableFindSolution =  new Draggable(".dragger", {
        bounds: ".selector_hld",
        throwProps: true,
        onDrag: moveAction,
        type:"x",
        onThrowUpdate: moveAction,
        onRelease : function(e){ 
          if($('.selector_hld').hasClass('babyHld')){
            updateBabyOnDragger({per:xp,});
          } 
          if($('.selector_hld').hasClass('adultHld')){
            updateAdultOnDragger({per:xp,});
          } 
        },
    });

    moveAction.call(draggable);
    function moveAction() {
        xp = this.x / (this.maxX || 1);
    }
    var tl = new TimelineMax();

    function updateBabyOnDragger(param){
      var per = param.per;
      currPosition = per;

      if(currPosition > 0 && currPosition < 1 ){
        TweenMax.to(".dragger",0.5,{x:0, ease: Power0.easeNone,});
      }else if(currPosition < 0 && currPosition > -1){  
          TweenMax.to(".dragger",0.5,{x:0, ease: Power0.easeNone, });
      }

      if(currPosition == -1){
        if($('.selector_hld').hasClass('babyHld')){
          redirectToBabyPage();
        }
      }

      if(currPosition == 1){
        if($('.selector_hld').hasClass('babyHld')){
          //replaceImages();
          xp=0;
          TweenMax.to(".dragger",0.3,{opacity:0, scale:0, ease: Power0.easeNone, onComplete:function(){
            $('.selector_hld').removeClass('babyHld');
            $('.selector_hld').addClass('adultHld');
            TweenMax.set('.back_arrow',{display:'block'})
            tl.to('.dg_pos',0.3,{opacity:0, onComplete:function(){
                replaceImages();
            }},'sameTime')
              .set('.dg_pos',{scale:0})
              .to('.dragger',0.3,{x:0},'sameTime')
              .to('.draggerLine',0.3,{width:0},'sameTime')
              .to('.draggerLine',0.3,{width:'90%', ease:Sine.easeOut})
              .to('.dg_pos',0.3,{opacity:1, scale:1, ease:Sine.easeOut})
              .to('.dragger',0.3,{opacity:1,scale:1, ease:Sine.easeOut})
              .to('.back_arrow',0.3,{opacity:1, ease:Sine.easeOut})
              
          }},'sameTime');
        }
      }
      
      if($('.selector_hld').hasClass('babyHld')){
        //TweenMax.to(".bg_baby",0.5,{opacity:-currPosition, ease: Power0.easeNone,}, 'change');
      }
      $('.dragger').unbind('click'); 
    }

    function updateAdultOnDragger(param){
      var per = param.per;
      currPosition = per;
      if(currPosition > 0 && currPosition < 1 ){
        TweenMax.to(".dragger",0.5,{x:0, ease: Power0.easeNone,});
      }else if(currPosition < 0 && currPosition > -1){  
          TweenMax.to(".dragger",0.5,{x:0, ease: Power0.easeNone, });
      }

      if(currPosition == -1){
        if($('.selector_hld').hasClass('adultHld')){
          redirectToSkinPage();
        }
      }

      if(currPosition == 1){
        redirectToHairPage();
      }
    }

    function replaceImages(){
      $('.dg_pos.f-left div').find('img').attr('src', basePath+'/assets/images/home/type-skin.jpg');
      $('.dg_pos.f-right div').find('img').attr('src', basePath+'/assets/images/home/type-hair.jpg');
      $('.dg_pos.f-left').find('p').text('Skin');
      $('.dg_pos.f-right').find('p').text('Hair');
    }

    function resetDraggerToInit(){
      $('.dg_pos.f-left div').find('img').attr('src', basePath+'/assets/images/home/type-baby.jpg');
      $('.dg_pos.f-right div').find('img').attr('src', basePath+'/assets/images/home/type-adult.jpg');
      $('.dg_pos.f-left').find('p').text('Your Baby');
      $('.dg_pos.f-right').find('p').text('For You');
      $('.selector_hld').removeClass('adultHld');
      $('.selector_hld').addClass('babyHld');
      TweenMax.to(".dragger",0.3,{x:0, ease: Power0.easeNone, });
      TweenMax.to(".back_arrow",0.3,{opacity:0, ease: Power0.easeNone, });
    }

    $('.back_arrow').bind('click', function(){
      resetDraggerToInit();
    });

    function redirectToBabyPage(){
      setTimeout(function(){ 
        window.location.href="baby-products-1";
      }, 1000);
    }

    function redirectToSkinPage(){
      setTimeout(function(){ 
        window.location.href="skin-products-3";
      }, 1000);
    }

    function redirectToHairPage(){
      setTimeout(function(){ 
        window.location.href="hair-products-2";
      }, 1000);
    }

    /* A N M T I O N    S E C T I O N */

    function homeAnimation() {
      var tl = new TimelineLite();
      var sellerHeading = $('#seller h2');
      var perObj = {percent:0};
      var perTwenObj = {percent:0};
      var perTwenObjEven = {percent:0};
      var sellerItemArr = $('#seller').find('.grid-item');
      function setSllerBlurbAnim(prop){
        var mod = prop.i%3;
        if(mod == 0){
          TweenMax.set(sellerItemArr[prop.i],{y:prop.y*300});
        }else if(mod == 1){
          TweenMax.set(sellerItemArr[prop.i],{y:prop.y*100});
        }else if(mod == 2){
          TweenMax.set(sellerItemArr[prop.i],{y:prop.y*500});
        }
      }

      var tilesTween = TweenMax.to(perObj, 1, {percent:1, ease:Power0.easeNone, onUpdate:function(){
            TweenMax.killTweensOf(perTwenObj);
            TweenMax.to(perTwenObj, 0.3, {percent:perObj.percent, ease:Sine.easeOut, onUpdate:function(){
              var y = (1-perTwenObj.percent);
              TweenMax.set(sellerHeading,{y:y*50});
              for(i=0; i < sellerItemArr.length; i++){
                setSllerBlurbAnim({i:i,y:y})
              }
            }})            
          } 
      })
      //-----------------------------
      var scene1 = new ScrollMagic.Scene({
        triggerElement: "#seller",
        triggerHook: 1,
        duration: winHT*2.1,
        offset: winHT*0.2
      })
      .setTween(tilesTween)
      //.addIndicators({ name: "sectionFeatures" })
      .addTo(scrollMagicController);

      tl.fromTo('#seller h2', 0.4, {opacity:0, y:50},{opacity:1, y:0,ease:Sine.easeInOut})
      var scene2 = new ScrollMagic.Scene({
        triggerElement: "#seller",
        triggerHook: 0.8,
        reverse:true
      })
      .setTween(tl)
      .addTo(scrollMagicController);

      /***************************   Product Type Anim  ************************************/
      var productTypeTL = new TimelineLite();
      productTypeTL.fromTo('#product_type h2', 0.4, {opacity:0, y:30},{opacity:1, y:0,ease:Sine.easeInOut}, 'pro_ty_start')
                  .fromTo('.draggerLine', 0.5,{width:0},{width:'90%', ease:Sine.easeInOut},'pro_ty_start-=0.2')
                  .staggerFromTo('.pro_type',0.4,{scale:0},{scale:1}, 0.1)
      var homeProduct = new ScrollMagic.Scene({
        triggerElement: "#product_type",
        triggerHook: 0.75,
        reverse:true
      })
      .setTween(productTypeTL)
      .addTo(scrollMagicController);

      /***************************   Product Type Anim  ************************************/


      /***************************   Why PH Anim  ************************************/
      var WhyPHImgTL = new TimelineLite();
      WhyPHImgTL.to(".ph_banner img",1, { y:'-40%', ease:Sine.easeInOut})

      var tweenWhyPhBanner = new ScrollMagic.Scene({
        triggerElement: "#hm_why_ph", 
        triggerHook: 0.6,
        duration: winHT*1.1
      })
      .setTween(WhyPHImgTL)
      //.addIndicators()
      .addTo(scrollMagicController);

      var WhyPHTL = new TimelineLite();
      WhyPHTL.fromTo('#hm_why_ph h2', 0.3, {opacity:0, y:30},{opacity:1, y:0,ease:Sine.easeInOut})
             .fromTo('#hm_why_ph p',0.6, {opacity:0, x:60},{opacity:1, x:0, ease:Sine.easeIn},'why_ph_start')
             .fromTo('#hm_why_ph .button',0.4, {opacity:0, x:80},{opacity:1, x:0, ease:Sine.easeIn},'why_ph_start-=0.002')

      var tweenWhyPhBanner = new ScrollMagic.Scene({
        triggerElement: "#hm_why_ph", 
        triggerHook: 0.6
      })
      .setTween(WhyPHTL)
      //.addIndicators()
      .addTo(scrollMagicController);

      /***************************   Why PH Anim  ************************************/



      /***************************   Home Media Anim  ********************************/
      var mediaTL = new TimelineLite();
      mediaTL.fromTo('.video_blurb',0.5,{opacity:0, y:70},{opacity:1, y:0, ease:Sine.easeIn})
      var tweenMedia = new ScrollMagic.Scene({
        triggerElement: "#hm_media_hld", 
        triggerHook: 0.6
      })
      .setTween(mediaTL)
      //.addIndicators()
      .addTo(scrollMagicController);
     /***************************   Home Media Anim  ********************************/



      /***************************   Try Us Anim  ********************************/
      var tryUsTL = new TimelineLite();
      tryUsTL.fromTo('#try_us h2', 0.3, {opacity:0, y:30},{opacity:1, y:0,ease:Sine.easeInOut})
             .staggerFromTo('#try_us .sellers a', 0.3, {opacity:0, y:50},{opacity:1, y:0,ease:Sine.easeIn}, 0.1,'-=0.15')
      var tweenBlog = new ScrollMagic.Scene({
        triggerElement: "#try_us", 
        triggerHook: 0.6
      })
      .setTween(tryUsTL)
      //.addIndicators()
      .addTo(scrollMagicController);

      /***************************   Try Us Anim  ********************************/


    }

    if(!isMobile.any()){
      homeAnimation();
    }


    setupYoutubePlayer = function (){
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        
        // 3. This function creates an <iframe> (and YouTube player)
        //    after the API code downloads.
        
        window.playYoutubeVideo = function(){
            player.playVideo();
        }
        window.onYouTubeIframeAPIReady = function () {
            player = new YT.Player('player', {
            height: '390',
            width: '640',
            videoId: 'COh6VZrL_PM',
            playerVars:{
                showinfo:0,
                rel:0,
                modestbranding:1,
                autohide:1,
                showinfo:0,
                color:"#ffff00"
            },
            events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
            }
            });
        }

        // 4. The API will call this function when the video player is ready.
        function onPlayerReady(event) {
            //event.target.playVideo();
            //window.playYoutubeVideo();
        }

        // 5. The API calls this function when the player's state changes.
        //    The function indicates that when playing a video (state=1),
        //    the player should play for six seconds and then stop.
        var done = false;
        function onPlayerStateChange(event) {
            if (event.data == YT.PlayerState.PLAYING && !done) {
            /*setTimeout(stopVideo, 6000);
            done = true;*/
            }
        }
        window.stopYoutubeVideo = function () {
            player.stopVideo();
        }
    }

    setupYoutubePlayer();

      $('#VideoHolder .close_btn').bind('click', function(){
        $('#VideoHolder').css({'display':'none'});
        window.stopYoutubeVideo();
      });
      
      $('#VideoHolder .videoBackground').bind('click', function(){
        $('#VideoHolder').css({'display':'none'});
        window.stopYoutubeVideo();
      });
});


$(window).on('load', function(){
  
  TweenMax.set('#VideoHolder',{display:'block', opacity:0, onComplete:function(){
    TweenMax.to('#VideoHolder',0.5,{opacity:1, onComplete:function(){
      window.playYoutubeVideo();
    }});
  }})

});

    
